import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { WagmiConfig } from "wagmi";
import { wagmiClient, ethereumClient } from "./helper/wagmi";
import { Web3Modal } from "@web3modal/react";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <WagmiConfig client={wagmiClient}>
      <App />
      <Web3Modal projectId={process.env.REACT_APP_PROJECT_ID} ethereumClient={ethereumClient} themeVariables={{
        '--w3m-accent-color': '#FCD535',
        '--w3m-background-color': '#FCD535',

      }} />
    </WagmiConfig>
  </React.StrictMode>
);