import MenuOpenOutlinedIcon from '@mui/icons-material/MenuOpenOutlined';
import LayersOutlinedIcon from '@mui/icons-material/LayersOutlined';
import DashboardIcon from '@mui/icons-material/Dashboard';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import logoImg from '../img/logo-full.png'
import minilogoImg from '../img/logo.png'
import SpaIcon from '@mui/icons-material/Spa';
import CoPresentIcon from '@mui/icons-material/CoPresent';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import AirplaneTicketIcon from '@mui/icons-material/AirplaneTicket';
import DataThresholdingIcon from '@mui/icons-material/DataThresholding';
import LocalPoliceIcon from '@mui/icons-material/LocalPolice';



function Sidebar() {

    const handleToggle = () => {
        console.log('clicked')
        document.body.classList.toggle('toggle-sidebar');
    }

    return (
        <>
            <aside className="sidebar">
                <div className='sidebar-line'>
                    <div className="sidebar-logo d-flex align-items-center justify-content-between">
                        <a href="#sec" class="header-logo">
                            {/* <img src="assets/images/logo.svg" class="img-fluid light-logo" alt="logo" /> */}
                            <img src={logoImg} className='big-logo' height="40px" width="150px" alt='logo' />
                            <img src={minilogoImg} className='mini-logo' height="40px" width="40px" alt='logo' />
                        </a>
                        <div className="menu-bt-sidebar" style={{cursor : "pointer"}}  onClick={()=>handleToggle()}>
                            <MenuOpenOutlinedIcon sx={{ color: "white" }} fontSize='large' />
                        </div>
                    </div>
                </div>
                <div className='sidebar-menu'>
                    <ul className="sidebar-menu-nav">
                        <li>
                            <a href="https://aomfund.io/" target='_blank' rel="noreferrer">
                                <DashboardIcon sx={{ color: "#fff" }} className='me-2' />
                                <span>Website</span>
                            </a>
                        </li>
                        <li>
                            <a href="#sec" className='active'>
                                <EmojiEventsIcon sx={{ color: "#FCD535" }} className='me-2' />
                                <span>Invest</span>
                            </a>
                        </li>
                        <li>
                            <a href="https://aomzex.com/" target='_blank' rel="noreferrer">
                                <LayersOutlinedIcon sx={{ color: "#fff" }} className='me-2' />
                                <span>Exchange</span>
                            </a>
                        </li>
                        <li>
                            <a href="#sec" target='_blank' rel="noreferrer">
                                <SpaIcon sx={{ color: "#fff" }} className='me-2' />
                                <span>Wellness Center</span>
                            </a>
                        </li>
                        <li>
                            <a href="#sec" target='_blank' rel="noreferrer">
                                <CoPresentIcon sx={{ color: "#fff" }} className='me-2' />
                                <span>Linkspace Together</span>
                            </a>
                        </li>
                        <li>
                            <a href="https://cofinex.io/signup?referralCode=M5KYH8NI" target='_blank' rel="noreferrer">
                                <CreditCardIcon sx={{ color: "#fff" }} className='me-2' />
                                <span>Copay Card</span>
                            </a>
                        </li>
                        <li>
                            <a href="https://www.flycnx.com" target='_blank' rel="noreferrer">
                                <AirplaneTicketIcon sx={{ color: "#fff" }} className='me-2' />
                                <span>Fly With Aomz</span>
                            </a>
                        </li>
                        {/* <li>
                            <a href="#sec" target='_blank' rel="noreferrer">
                                <img height="24px" width="24px" src="https://media4.giphy.com/media/v1.Y2lkPTc5MGI3NjExdWI0dmwybWp3OHRicmN3OXB3NmdnOGJlaHhiZHJrb2ZxbmwydWYybSZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9cw/McsDYx2ihXzztTFMap/giphy.gif" className='me-2' />
                                <span style={{ color: "#FCD535" }}>Live Gold</span>
                            </a>
                        </li> */}
                        <li>
                            <a href="#sec" target='_blank' rel="noreferrer">
                                <DataThresholdingIcon sx={{ color: "#fff" }} className='me-2' />
                                <span>FEDU&T</span>
                            </a>
                        </li>
                        <li>
                            <a href="#sec" target='_blank' rel="noreferrer">
                                <LocalPoliceIcon sx={{ color: "#fff" }} className='me-2' />
                                <span>Al RoBot Trade</span>
                            </a>
                        </li>
                        <li>
                            <a href="http://aommarket.com/" target='_blank' rel="noreferrer">
                                <LocalPoliceIcon sx={{ color: "#fff" }} className='me-2' />
                                <span>AOM Market</span>
                            </a>
                        </li>
                        <li>
                            <a href="http://aomview.com/" target='_blank' rel="noreferrer">
                                <LocalPoliceIcon sx={{ color: "#fff" }} className='me-2' />
                                <span>AOM View</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </aside>
        </>
    )
}
export default Sidebar