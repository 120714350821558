import { useEffect, useState } from "react"
import bscstakeAbi from '../json/bscstake.json';
import { toast } from "react-toastify";
import { DECIMALS, DEFAULT_CHAIN, contract } from "../helper/constant";
import { getMultiCall } from "../helper/contractHelper";
import { getWeb3 } from "../helper/functions";




export const useCommonStats = (updater) => {

    let web3 = getWeb3();


    const [stats, setStats] = useState({
        totalDistributed: 0,
        totalStaked: 0,
        totalUnstaked: 0,
        totalStakers: 0,
        stakeAmounts: [],
        stakeApys: [],
        stakeDays : [],
        getTokenPrice: 0,
        RefLevel : [],
        claimperiod : 0
    });

    let stakeContract = new web3.eth.Contract(bscstakeAbi, contract[DEFAULT_CHAIN].STAKE_ADDRESS);

    useEffect(() => {
        const fetch = async () => {
            try {
                const data = await getMultiCall([
                    stakeContract.methods.StakersCount(), //0
                    stakeContract.methods.totalDistributed(), //1
                    stakeContract.methods.totalStaked(), //2
                    stakeContract.methods.totalUnstaked(), //3
                    stakeContract.methods.contractInfo() //4
                ]);

  
                setStats({
                    totalStakers: data[0],
                    totalDistributed: data[1] / Math.pow(10, DECIMALS),
                    totalStaked: data[2] / Math.pow(10, DECIMALS),
                    totalUnstaked: data[3] / Math.pow(10, DECIMALS),
                    RefLevel : data[4][0],
                    stakeAmounts : data[4][1],
                    stakeDays : data[4][2],
                    stakeApys : data[4][3],
                    claimperiod : data[4][4],
                    getTokenPrice: data[4][5] / Math.pow(10, 18)
                })
            }
            catch (err) {
                console.log(err.message);
                toast.error(err.reason)
            }
        }


        fetch();

        // eslint-disable-next-line
    }, [updater]);

    return stats;
}
