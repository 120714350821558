import React, { useEffect, useState } from 'react'
import { DEFAULT_CHAIN, contract } from '../helper/constant';
import { daysPassed, formatPrice, getWeb3, shorterDate } from '../helper/functions';
import stakeAbi from '../json/bscstake.json';
import { useAccount, useNetwork } from 'wagmi';
import { getContract, getMultiCall } from '../helper/contractHelper';
import { toast } from 'react-toastify';
import { useEthersSigner } from '../hooks/useEthersProvider';
import TabButton from './TabButton';
import MyWallet from './MyWallet';
import { useStakeStats } from '../hooks/useBscAccount';
import { ClipLoader } from 'react-spinners';


export default function Unstake({ accStats, setUpdater, updater, setTab, tab }) {
    const { address, isConnected } = useAccount()
    const { chain } = useNetwork()
    const [tLoading, setTLoading] = useState(['', false]);
    const signer = useEthersSigner()
    const [page, setPage] = useState(1)
    const stakeStats = useStakeStats(updater, page)
    const [slotRevenue, setSlotRevenue] = useState([]);



    const override = {
        display: "block",
        marginRight: "auto",
        marginLeft: "auto"
    };

    const handleTerminate = async (index,type=1) => {
        setTLoading([index, true]);
        try {

            if (address && isConnected) {
                if (chain.id === DEFAULT_CHAIN) {
                    let tokenStakingAddress = contract[DEFAULT_CHAIN].STAKE_ADDRESS;
                    let stakeContract = getContract(stakeAbi, tokenStakingAddress, signer);

                    let tx = await stakeContract.withdraw(index,type, { 'from': address });
                    const resolveAfter3Sec = new Promise(resolve => setTimeout(resolve, 5000));
                    toast.promise(
                        resolveAfter3Sec,
                        {
                            pending: 'Waiting for confirmation',
                        }
                    )

                    var interval = setInterval(async function () {
                        let web3 = getWeb3(DEFAULT_CHAIN);
                        var response = await web3.eth.getTransactionReceipt(tx.hash);
                        if (response != null) {
                            clearInterval(interval)
                            if (response.status === true) {
                                toast.success('success ! your last transaction is success.');
                                window.location.reload()
                                setTLoading(['', false]);
                            }
                            else if (response.status === false) {
                                toast.error('error ! Your last transaction is failed.');
                                window.location.reload()
                                setTLoading(['', false]);
                            }
                            else {
                                toast.error('error ! something went wrong.');
                                window.location.reload()
                                setTLoading(['', false]);
                            }
                        }
                    }, 5000);
                }
                else {
                    toast.error('Please select Binance Mainnet Network !');
                    setTLoading(['', false]);
                }
            }
            else {
                toast.error('Please Connect Wallet!');
                setTLoading(['', false]);
            }

        }
        catch (err) {
            toast.error(err.reason);
            setTLoading(['', false]);
        }
    }

    const handleClaim = async (index) => {
        setTLoading([index, true]);
        try {

            if (address && isConnected) {
                if (chain.id === DEFAULT_CHAIN) {
                    let tokenStakingAddress = contract[DEFAULT_CHAIN].STAKE_ADDRESS;
                    let stakeContract = getContract(stakeAbi, tokenStakingAddress, signer);

                    let tx = await stakeContract.claim(index, { 'from': address });
                    const resolveAfter3Sec = new Promise(resolve => setTimeout(resolve, 5000));
                    toast.promise(
                        resolveAfter3Sec,
                        {
                            pending: 'Waiting for confirmation',
                        }
                    )

                    var interval = setInterval(async function () {
                        let web3 = getWeb3(DEFAULT_CHAIN);
                        var response = await web3.eth.getTransactionReceipt(tx.hash);
                        if (response != null) {
                            clearInterval(interval)
                            if (response.status === true) {
                                toast.success('success ! your last transaction is success.');
                                window.location.reload()
                                setTLoading(['', false]);
                            }
                            else if (response.status === false) {
                                toast.error('error ! Your last transaction is failed.');
                                window.location.reload()
                                setTLoading(['', false]);
                            }
                            else {
                                toast.error('error ! something went wrong.');
                                window.location.reload()
                                setTLoading(['', false]);
                            }
                        }
                    }, 5000);
                }
                else {
                    toast.error('Please select Binance Mainnet Network !');
                    setTLoading(['', false]);
                }
            }
            else {
                toast.error('Please Connect Wallet!');
                setTLoading(['', false]);
            }

        }
        catch (err) {
            toast.error(err.reason);
            setTLoading(['', false]);
        }
    }


    useEffect(() => {
        let intervalId;
        async function fetch() {
            try {
                if (address) {
                    let web3 = getWeb3(DEFAULT_CHAIN);
                    let stakeContract = new web3.eth.Contract(stakeAbi, contract[DEFAULT_CHAIN].STAKE_ADDRESS);
                    let getRevenueArray = [];
                    let end = stakeStats.end >= stakeStats.totalLength ? stakeStats.totalLength : stakeStats.end;
                    let z = 0;

                    for (let i = stakeStats.start; i <= end; i++) {
                        getRevenueArray[z] = stakeContract.methods.slotRevenue(address, i);
                        z++;
                    }

                    let revenueHistory = await getMultiCall(getRevenueArray);
                    setSlotRevenue(revenueHistory);
                }
                else {
                    setSlotRevenue([]);
                }
            }
            catch (err) {
                console.log(err.message)
            }

        }
        if (address) {
            fetch();

            intervalId = setInterval(fetch, 60000);
        }

        return () => {
            if (intervalId) clearInterval(intervalId);
        };
    }, [stakeStats, address])

    return (
        <div className='d-flex justify-content-center mb-4 row' style={{ marginLeft: 0 }}>
            <div className='col-12 col-xl-7 card'>
                <TabButton setTab={setTab} tab={tab} />
                <div className='table-responsive'>
                    <table class="table table-bordered border-primary table-unstake">
                        <thead>
                            <tr>
                                <th scope="col">Invest</th>
                                <th scope="col">Plan($)</th>
                                <th scope="col">APY</th>
                                <th scope="col">Reward</th>
                                <th scope="col">Start</th>
                                <th scope="col">End</th>
                                <th scope="col">Time(D)</th>
                                <th scope="col">Earn</th>
                                <th scope="col">Action</th>
                                <th scope="col">AOMZ</th>
                                <th scope="col">DTC</th>
                            </tr>
                        </thead>
                        <tbody>
                            {stakeStats.loading ? (
                                <tr>
                                    <td colSpan={11} className='p-4'>
                                        <ClipLoader
                                            color="#fff"
                                            loading={true}
                                            cssOverride={override}
                                            size={50}
                                        // className="spinner"
                                        />
                                    </td>
                                </tr>
                            ) : (
                                stakeStats.stakeHistory && stakeStats.stakeHistory.length > 0 ? (
                                    stakeStats.stakeHistory.map((row, index) => {
                                        
                                        return (
                                            <tr key={index}>
                                                <td>{row._amount ? formatPrice(row._amount / Math.pow(10, accStats.tokenDecimals)) : 0}</td>
                                                <td>${row._amountUsd ? formatPrice(row._amountUsd / Math.pow(10, accStats.tokenDecimals)) : 0}</td>
                                                <td>{row._apy ? formatPrice(row._apy / Math.pow(10, 2), 3) : 0}%</td>
                                                <td><span style={{ color: "#00B259", fontWeight: "700" }}>{slotRevenue.length > 0 && slotRevenue[index] && slotRevenue[index][0] ? formatPrice(slotRevenue[index][0] / Math.pow(10, accStats.tokenDecimals), 5) : 0}</span></td>
                                                <td>{row._startTime ? shorterDate(row._startTime * 1000) : ' - '}</td>
                                                <td>{row._endTime ? shorterDate(row._endTime * 1000) : ' - '}</td>
                                                <td>{Math.round(Math.abs((row._endTime - Math.floor(new Date().getTime() / 1000.0)) / 86400))}</td>
                                                <td>{row._claimedReward && row._claimedReward > 0 ? formatPrice(row._claimedReward / Math.pow(10, 18)) : 0}</td>
                                                <td>
                                                    <button onClick={() => handleClaim(index)} disabled={(slotRevenue.length > 0 && slotRevenue[index] && slotRevenue[index][2]) &&  parseFloat(row._claimedReward) < parseFloat(row._totalReward)  ? false : true} type="button" className="theme-btn1">
                                                        {tLoading[0] === index && tLoading[1] ? 'Loading...' : 'Claim'}
                                                    </button>
                                                </td>
                                                <td>
                                                    <button onClick={() => handleTerminate(index , 1)} disabled={(slotRevenue.length > 0 && slotRevenue[index] && slotRevenue[index][1]) ? false : true} type="button" className="theme-btn1">
                                                        {tLoading[0] === index && tLoading[1] ? 'Loading...' : 'Withdraw'}
                                                    </button>
                                                </td>
                                                <td>
                                                    <button onClick={() => handleTerminate(index , 2)} disabled={(slotRevenue.length > 0 && slotRevenue[index] && slotRevenue[index][3]) ? false : true} type="button" className="theme-btn1">
                                                        {tLoading[0] === index && tLoading[1] ? 'Loading...' : 'Withdraw'}
                                                    </button>
                                                </td>
                                            </tr>
                                        )
                                    })

                                ) : (
                                    <tr>
                                        <td colSpan={11}>
                                            <h5 className="p-5 text-white text-center">You Have No Earn Record Yet.</h5>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                    <div className='d-flex justify-content-end mb-3'>
                        {
                            parseInt(page) > 1 &&

                            <button className='btn btn-sm btn-outline-light mx-2' onClick={() => {
                                setPage(page - 1);

                            }}>
                                Prev
                            </button>


                        }
                        {parseInt(page) < parseInt(stakeStats.totalPages) &&

                            <button className='btn btn-sm btn-outline-light mx-2' onClick={() => {
                                setPage(page + 1);

                            }}>
                                Next
                            </button>

                        }
                    </div>
                </div>
            </div>
            <MyWallet accStats={accStats} />
        </div>

    )
}
