import { bsc } from "wagmi/chains";


export const contract = {
  97: {
    STAKE_ADDRESS: "0x85e814413dab3053d94b855a18cc411912529dc7",
    MULTICALL_ADDRESS: "0xa54fE4a3DbD1Eb21524Cd73754666b7E13b4cB18",
    TOKEN_ADDRESS: "0xaC2b26a4423a05d1307157aF2450acf199921300",
    DTC_ADDRESS : "0x00cD1D9DF99EAc77aB154d33AedECc50b179194f"
  },
  56: {
    STAKE_ADDRESS: "0x6847ff9ac87fc41e16ca4be1e2b92440f207a116",
    MULTICALL_ADDRESS: "0x2cc8fe3e04d7b1edaeb42e2e73f78c4cac074116",
    TOKEN_ADDRESS: "0xDFe7D53b2c9b01Fc4809a469a5c57156e61110aA",
    DTC_ADDRESS : "0x7a171595Bd0b06c2D5Ad80059896efC432477e1b"
  }
}

export const DEFAULT_CHAIN = 56;
export const DEFAULT_RPC = "https://bsc-dataseed1.binance.org/"
// export const DEFAULT_RPC = "https://data-seed-prebsc-2-s1.binance.org:8545/"
export const PROJECT_ID = "27b9720018bf7f8740936e6a6eb28604";
export const DECIMALS = 18;
export const SYMBOL = 'AOMZ';
export const EXPLOER = 'https://bscscan.com/';
export const chains = [bsc]
export const DTC_IMG = "https://alchemy.mypinata.cloud/ipfs/QmdZHV3xkMXGmXUdn9LDHg14M6W4LNHGKBN9SHwAKmeJ2w"
export const AOMZ_IMG = "https://alchemy.mypinata.cloud/ipfs/QmQTNRPW4n2atYUw29GJJnTh8YozFJDK1E4rnWWZxVNR5w"






