import React, { useEffect, useMemo, useState } from 'react';
import logoImg from '../img/logo.png';
import { formatPrice, getWeb3 } from '../helper/functions';
import { DECIMALS, DEFAULT_CHAIN, contract, days } from '../helper/constant';
import stakeAbi from '../json/bscstake.json';
import tokenAbi from '../json/token.json';
import { useAccount, useNetwork } from 'wagmi';
import { useEthersSigner } from '../hooks/useEthersProvider';
import { getContract } from '../helper/contractHelper';
import { toast } from 'react-toastify';
import { useWeb3Modal } from '@web3modal/react';
import { ethers } from 'ethers';
import { useLocation } from 'react-router-dom';
import Loading from './Loading';
import MyWallet from './MyWallet';
import TabButton from './TabButton';
import CustomButton from './CustomButton';
import Slider from '@mui/material/Slider';
import usdtImg from '../img/dtc.png';



export default function Stake({ commonStats, accStats, setUpdater, setTab, tab }) {
    const { address, isConnected } = useAccount()
    const { chain } = useNetwork();
    const signer = useEthersSigner()
    const { open } = useWeb3Modal()
    const [amountkey, setAmountkey] = useState(0);
    const [amount, setAmount] = useState(0);
    const [days, setDays] = useState(0);
    const [durationIndex, setDurationIndex] = useState(0);
    const [doller, setDoller] = useState(10);
    let web3 = getWeb3(DEFAULT_CHAIN);
    const [loading, setLoading] = useState(false);
    const [refAddress, setRefAddress] = useState('');
    const search = useLocation().search;

    const sliderOnChange = (event, value) => {
        if (value !== 0) {

            setDays(value);

            // Check if commonStats.stakeAmounts is defined and not empty
            if (commonStats.stakeDays) {
                // Find the index of the current value
                const index = commonStats.stakeDays.findIndex(rowdata => parseFloat(rowdata) === parseFloat(value));

                // Only set amountKey if the index is valid
                if (index !== -1) {
                    setDurationIndex(index); // Use the found index
                }
            }
        }
    };

    useEffect(() => {
        let refAddr = '';
        const queryChainId = new URLSearchParams(search).get('ref');
        if (queryChainId !== '') {
            refAddr = queryChainId;
        }
        setRefAddress(refAddr);
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        async function fetch() {
            try {

                let stakeContract = new web3.eth.Contract(stakeAbi, contract[DEFAULT_CHAIN].STAKE_ADDRESS);
                let tamount = commonStats.stakeAmounts[amountkey];
                let tokenAmount = await stakeContract.methods.getAmountsOut(tamount).call();
                setAmount(parseFloat(tokenAmount / Math.pow(10, DECIMALS)));
            }
            catch (err) {
                console.log(err.message);
            }
        }

        fetch();
        // eslint-disable-next-line
    }, [amountkey, commonStats])

    const handleApprove = async () => {
        if (address) {
            if (chain.id === DEFAULT_CHAIN) {
                try {

                    setLoading(true);

                    let tokenContract = getContract(tokenAbi, contract[DEFAULT_CHAIN].TOKEN_ADDRESS, signer);
                    let amount1 = ethers.utils.parseEther('1000000000000');

                    let tx = await tokenContract.approve(contract[DEFAULT_CHAIN].STAKE_ADDRESS, amount1, { 'from': address });
                    const resolveAfter3Sec = new Promise(resolve => setTimeout(resolve, 5000));
                    toast.promise(
                        resolveAfter3Sec,
                        {
                            pending: 'Waiting for confirmation',
                        }
                    )

                    var interval = setInterval(async function () {
                        let web3 = getWeb3(DEFAULT_CHAIN);
                        var response = await web3.eth.getTransactionReceipt(tx.hash);
                        if (response != null) {
                            clearInterval(interval)
                            if (response.status === true) {
                                toast.success('success ! your last transaction is success 👍');
                                setLoading(false);
                                setUpdater(Math.random());
                            }
                            else if (response.status === false) {
                                toast.error('error ! Your last transaction is failed.');
                                setLoading(false);
                                setUpdater(Math.random());
                            }
                            else {
                                toast.error('error ! something went wrong.');
                                setLoading(false);
                                setUpdater(Math.random());
                            }
                        }
                    }, 5000);
                }
                catch (err) {
                    console.log(err.message)
                    toast.error(err.reason ? err.reason : err.message);
                    setLoading(false);
                }
            }
            else {
                toast.error('Please select Binance Mainnet Network !');
                setLoading(false);
            }

        }
        else {
            toast.error('Please Connect Wallet!');
            setLoading(false);
        }
    }

    const handleStake = async () => {
        setLoading(true);
        try {

            if (address && isConnected) {
                if (chain.id === DEFAULT_CHAIN) {

                    if (parseFloat(accStats.stakeTokenBalance) >= parseFloat(amount)) {

                        let tokenStakingAddress = contract[DEFAULT_CHAIN].STAKE_ADDRESS;
                        let stakeContract = getContract(stakeAbi, tokenStakingAddress, signer);
                        let refAddr = refAddress ? refAddress : '0x0000000000000000000000000000000000000000';

                        let tx = await stakeContract.stake(amountkey, durationIndex , refAddr, { 'from': address });
                        const resolveAfter3Sec = new Promise(resolve => setTimeout(resolve, 5000));
                        toast.promise(
                            resolveAfter3Sec,
                            {
                                pending: 'Waiting for confirmation 👌',
                            }
                        )

                        var interval = setInterval(async function () {
                            let web3 = getWeb3(DEFAULT_CHAIN);
                            var response = await web3.eth.getTransactionReceipt(tx.hash);
                            if (response != null) {
                                clearInterval(interval)
                                if (response.status === true) {
                                    toast.success('success ! your last transaction is success 👍');
                                    setUpdater(Math.random());
                                    setLoading(false);
                                }
                                else if (response.status === false) {
                                    toast.error('error ! Your last transaction is failed.');
                                    setUpdater(Math.random());
                                    setLoading(false);
                                }
                                else {
                                    toast.error('error ! something went wrong.');
                                    setUpdater(Math.random());
                                    setLoading(false);
                                }
                            }
                        }, 5000);

                    }
                    else {
                        toast.error('you don\'t have enough balance !');
                        setLoading(false);
                    }

                }
                else {
                    toast.error('Please select Binance Mainnet Network !');
                    setLoading(false);
                }
            }
            else {
                toast.error('Please Connect Wallet!');
                setLoading(false);
            }

        }
        catch (err) {
            toast.error(err.reason);
            setLoading(false);
        }
    }

    const marks = [
        { value: 15552000 },
        { value: 31104000 },
        { value: 46656000 },
        { value: 62208000 },
        { value: 93312000 }
    ];


    return (
        <div className='d-flex justify-content-center mb-4 row' style={{ marginLeft: 0 }}>
            <div className='col-12 col-md-6 card'>

                <div className='d-flex justify-content-center row'>
                    <TabButton setTab={setTab} tab={tab} />
                    <div className='col-12 col-xl-10'>
                        <div className='row'>
                            <div className='col-12 text-center'>
                                <h4 className='text-center mb-2'>Invest & Earn</h4>
                                {commonStats && commonStats.stakeAmounts && commonStats.stakeAmounts.length > 0 ? (
                                    commonStats.stakeAmounts.map((value, key) => {
                                        return (
                                            <button type='button' onClick={() => setAmountkey(key)} className={`btn ${key === amountkey ? 'btn-warning' : 'btn-warning-info bg-dark-1'} btn-amounts  my-2 mx-1 mb-md-0`}>
                                                ${formatPrice(value / Math.pow(10, DECIMALS))}
                                            </button>

                                        )
                                    })
                                ) : (
                                    <Loading />
                                )
                                }
                                <div className='input-around mt-4'>
                                    <div class="input-group">

                                        <button class="btn" type="button">
                                            <img src={logoImg} alt='bnb' height="40px" />
                                        </button>

                                        <input readOnly={true} value={formatPrice(amount)} style={{ textAlign: "center", height: "50px", background: "#121621", color: "#fff", }} type="text" class="form-control" placeholder="Enter amount" aria-label="Amount" aria-describedby="input-with-logo-button" />

                                        <button class="btn" type="button">
                                            <img src={usdtImg} alt='bnb' height="40px" />
                                        </button>
                                    </div>
                                </div>




                                <Slider
                                    value={days}
                                    min={15552000}
                                    max={93312000} // Set max to 300 based on your values
                                    step={null} // Prevent default step to allow jumping to marked values
                                    marks={marks} // Set the marks to the specific values
                                    onChange={sliderOnChange} // Handle change
                                    valueLabelDisplay="off"
                                    valueLabelFormat={(value) => `$${value}`} // Custom value display
                                    className="mb-2"
                                    sx={{
                                        color: "#FCD535",
                                        height: "14px"

                                    }}
                                />

                                {commonStats && commonStats.stakeDays && commonStats.stakeDays.length > 0 ? (
                                    commonStats.stakeDays.map((rowdata, key) => {
                                        return (
                                            <button className={`btn ${parseInt(key) === parseInt(durationIndex) ? 'btn-warning' : 'btn-warning-info bg-dark-1'} btn-periods mx-1 mb-3 mb-md-0`} onClick={() => {
                                                setDurationIndex(key);
                                                setDays(rowdata)
                                            }}>
                                                {parseInt(rowdata / 2592000)} Month
                                                {/* {parseInt(rowdata)} sec */}
                                            </button>
                                        )
                                    })
                                ) : (
                                    <Loading />
                                )
                                }

                                <div className='mt-5'>
                                    {
                                        address && isConnected ? (
                                            parseFloat(accStats.stakeTokenBalance) >= parseFloat(amount) && parseInt(amountkey) >= parseInt(accStats.userLevel > 0 ? parseInt(accStats.userLevel) - 1 : 0) ? (
                                                parseFloat(accStats.isApproved) >= parseFloat(amount) ? (
                                                    <CustomButton label='Invest Now' loading={loading} onClick={() => !loading ? handleStake() : null} />
                                                ) : (
                                                    <CustomButton label='Approve' loading={loading} onClick={() => !loading ? handleApprove() : null} />
                                                )
                                            ) : (
                                                <CustomButton label='Invest Now' disabled={true} />
                                            )
                                        ) : (
                                            <CustomButton label='Connect Wallet' onClick={() => open()} />
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <MyWallet accStats={accStats} />
        </div>
    )
}
