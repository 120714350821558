import React, { useMemo } from 'react'
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import SavingsIcon from '@mui/icons-material/Savings';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { formatPrice } from '../helper/functions';
import LockIcon from '@mui/icons-material/Lock';
import { SYMBOL } from '../helper/constant';

export default function TopCards({ commonStats }) {
    const maxAPY = useMemo(() => {
        return Math.max(...commonStats.stakeApys)
    }, [commonStats.stakeApys]);

    

    return (
        <div className='row mt-4'>
            <div className='col-sm-12 col-md-6 col-lg-3'>
                <div className='box-card back1-top'>
                    <div className='box-card-body'>
                        <div className='d-flex'>
                            <div className='icon-box'>
                                <MonetizationOnIcon sx={{ color: "#fff" }} fontSize="large" />
                            </div>
                            <div className='ms-3'>
                                <h6 className='mb-1 text-white'>{SYMBOL} Price</h6>
                                <p className='text-white'>${commonStats.getTokenPrice ? formatPrice(commonStats.getTokenPrice) : 0}</p>
                                {/* <p className='text-white'>$0.0827</p> */}
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-sm-12 col-md-6 col-lg-3'>
                <div className='box-card back2-top'>
                    <div className='box-card-body'>
                        <div className='d-flex'>
                            <div className='icon-box'>
                                <RecordVoiceOverIcon sx={{ color: "#fff" }} fontSize="large" />
                            </div>
                            <div className='ms-3'>
                                <h6 className='mb-1 text-white'>Total User</h6>
                                <p className='text-white'>{commonStats.totalStakers ? parseInt(commonStats.totalStakers) : 0}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-sm-12 col-md-6 col-lg-3'>
                <div className='box-card back3-top'>
                    <div className='box-card-body'>
                        <div className='d-flex'>
                            <div className='icon-box'>
                                <LockIcon sx={{ color: "#fff" }} fontSize="large" />
                            </div>
                            <div className='ms-3'>
                                <h6 className='mb-1 text-white'>Total Staked</h6>
                                <p className='text-white'>{commonStats.totalStaked ? formatPrice(commonStats.totalStaked) : 0}</p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-sm-12 col-md-6 col-lg-3'>
                <div className='box-card back4-top'>
                    <div className='box-card-body'>
                        <div className='d-flex'>
                            <div className='icon-box'>
                                <SavingsIcon sx={{ color: "#fff" }} fontSize="large" />
                            </div>
                            <div className='ms-3'>
                                <h6 className='mb-1 text-white'>Current APY</h6>
                                <p className='text-white'>UPTO 234%</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
